import { FC } from 'react'
import { useTranslation } from 'next-i18next'
import { IDashboardItemBase } from '@/types'
import styles from './index.module.scss'

type Props = {
  dashboardItem: IDashboardItemBase
}

export const DashBoardCard: FC<Props> = ({ dashboardItem }) => {
  const { t } = useTranslation(['dashboard'])

  return (
    <div className={styles.container}>
      <div className={styles.name}>{t(dashboardItem?.name)}</div>
      <div className={styles.content}>
        {dashboardItem?.numberDashboardItem.value} {t('件')}
      </div>
    </div>
  )
}
