import { FC, ReactElement } from 'react'
import classNames from 'classnames'
import styles from './index.module.scss'

type Props = {
  title: string
  renderRightContent?: () => ReactElement
  isWide?: boolean
}

export const PageHeader: FC<Props> = ({ title, renderRightContent, isWide = false }) => {
  return (
    <div className={isWide ? 'container-wide' : 'container'}>
      <div
        className={classNames(styles.container, {
          [styles.wide]: isWide,
        })}
      >
        <div className={styles.content}>
          <div
            className={classNames(styles.title, {
              [styles.wide]: isWide,
            })}
          >
            {title}
          </div>
          {renderRightContent && <div className={styles.right}>{renderRightContent()}</div>}
        </div>
      </div>
    </div>
  )
}
