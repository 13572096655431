import { GetServerSideProps, NextPage } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { observer } from 'mobx-react'
import { IDashboardItemBase, Language, MessageType } from '@/types'
import { getQueryValue, getStaticStores, useStores } from '@/utils'
import { MyHead } from '@components/molecules/MyHead'
import { PageHeader } from '@components/molecules/PageHeader'
import { useRouter } from 'next/router'
import { DashBoard } from '@/components/organisms/DashBoard'
import { AttackListPageTemplate } from '@/components/templates/AttackListPageTemplate'
import styles from './index.module.scss'

const Dashboard: NextPage = observer(() => {
  const { t } = useTranslation(['dashboard'])
  const { viewer, messages } = useStores()
  const router = useRouter()
  const targetUsername = getQueryValue('target_username', router)
  const [isLoadIndicator, setIsLoadIndicator] = useState(true)

  const fetchDashboard = async () => {
    const result = await viewer?.viewer?.fetchDashboard({ targetUsername })

    if (!result.isSuccessful) {
      messages.add({
        type: MessageType.Error,
        body: t('ダッシュボードデータの取得に失敗しました'),
        isDismissable: true,
        ttl: 5000,
      })
    }

    setIsLoadIndicator(false)
  }

  useEffect(() => {
    if (!viewer.isInitialized) {
      return
    }

    fetchDashboard()
  }, [viewer.isInitialized, targetUsername])

  const dashboardItems: IDashboardItemBase[] = viewer?.viewer?.dashboard?.dashboardItems

  return (
    <AttackListPageTemplate targetUsername={targetUsername} isLoadIndicator={isLoadIndicator}>
      <MyHead title={t('ダッシュボード')} noIndex />
      <div className={styles.container}>
        <div className={styles.heading}>
          <PageHeader title={t('ダッシュボード')} />
        </div>
        <div className='container'>
          <div className={styles.content}>
            <DashBoard dashboardItems={dashboardItems} />
          </div>
        </div>
      </div>
    </AttackListPageTemplate>
  )
})

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  const { viewer } = getStaticStores()
  await viewer.changeLanguage(locale as Language)

  return {
    props: {
      forcedTheme: 'light',
      ...(await serverSideTranslations(locale, ['global', 'p', 'dashboard'])),
    },
  }
}

export default Dashboard
