import { DashBoardCardList } from '@components/molecules/DashBoardCardList'
import { observer } from 'mobx-react'
import { FC } from 'react'
import { DashBoardBox } from '@components/molecules/DashBoardBox'
import { DashBoardCardListWrapper } from '@components/molecules/DashBoardCardListWrapper'
import { LoadingIndicator } from '@/components/atoms/LoadingIndicator'
import { IDashboardItemBase } from '@/types'
import { useTranslation } from 'next-i18next'
import styles from './index.module.scss'

type Props = {
  dashboardItems: IDashboardItemBase[]
  isGetting?: boolean
}

export const DashBoard: FC<Props> = observer(({ dashboardItems, isGetting }) => {
  const { t } = useTranslation(['dashboard'])
  return (
    <DashBoardBox title={t('案件管理')}>
      <DashBoardCardListWrapper>
        <DashBoardCardList dashboardItems={dashboardItems} />
        {isGetting && (
          <div className={styles.indicator}>
            <LoadingIndicator size='small' />
          </div>
        )}
      </DashBoardCardListWrapper>
    </DashBoardBox>
  )
})
