import { IDashboardItemBase } from '@/types'
import { DashBoardCard } from '@components/molecules/DashBoardCard'
import { observer } from 'mobx-react'
import { FC } from 'react'

type Props = {
  dashboardItems: IDashboardItemBase[]
}

export const DashBoardCardList: FC<Props> = observer(({ dashboardItems }) => {
  return (
    <>
      {dashboardItems?.map((item) => (
        <DashBoardCard key={item.name} dashboardItem={item} />
      ))}
    </>
  )
})
